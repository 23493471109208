import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Link} from 'react-router-dom'
import f1 from '../Assets/images/g1.jpg'
import f2 from '../Assets/images/g4.jpg'
import f3 from '../Assets/images/f3.jpg'
import f4 from '../Assets/images/g17.jpg'
import f5 from '../Assets/images/f5.jpg'
import f6 from '../Assets/images/yoga.png'
import f7 from '../Assets/images/f7.jpg'
import f8 from '../Assets/images/f8.png'
const Focussector = () => {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/focussector'); 
      };

      
  return (
    <div>
      
        <div className="container-fluid service pt-5">
            <div className="container py-5">
                <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: "800px"}}>
                    <h2 className="mainheading">Our Focus Sector</h2>
                    <h4 className="subheading mb-4">Leading the Way in Sector-Specific</h4>                  
                </div>
                <div className="row g-4 justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                    <div className="col-md-6 col-lg-6 col-xl-3  " >
                        <div className="service-item">
                            <div className="service-img">
                                <img src={f1} className="img-fluid focusimg rounded-top  " alt=""/>                           
                            </div>
                            <div className="service-content p-4 " id='diet'>
                                <div className="service-content-inner">
                                      <h3 className="d-inline-block h4 mb-4">Diet Food and Nutrition</h3>
                                    <button onClick={handleNavigation} className="btn btn-focus  rounded-pill py-2 px-4" >Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3  " >
                        <div className="service-item">
                            <div className="service-img">
                                <img src={f2}  className="img-fluid focusimg rounded-top " alt="" /> 
                            </div>
                            <div className="service-content p-4" id='diet'>
                                <div className="service-content-inner">
                                      <h3 className="d-inline-block h4 mb-4">Modern Lifestyle, Health and Fitness</h3>
                                    <button onClick={handleNavigation} className="btn btn-focus  rounded-pill py-2 px-4" >Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3  " >
                        <div className="service-item">
                            <div className="service-img">
                                <img src={f3} className="img-fluid  focusimg rounded-top " alt="" />
                            </div>
                            <div className="service-content p-4" id='diet'>
                                <div className="service-content-inner">
                                      <h3 className="d-inline-block h4 mb-4">Ayurveda and Naturopathy</h3>
                                    <button onClick={handleNavigation} className="btn btn-focus  rounded-pill py-2 px-4" >Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 " >
                        <div className="service-item">
                            <div className="service-img">
                                <img src={f4}  className="img-fluid focusimg rounded-top " alt="" />
                            </div>
                            <div className="service-content p-4" id='diet'>
                                <div className="service-content-inner">
                                      <h3 className="d-inline-block h4 mb-4">Fitness & Wellbeing Consultancy</h3>
                                    <button onClick={handleNavigation} className="btn btn-focus  rounded-pill py-2 px-4" >Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
                <div className="row py-5 g-4 justify-content-center wow fadeInUp" data-wow-delay="0.6s">
                    <div className="col-md-6 col-lg-6 col-xl-3 " >
                        <div className="service-item">
                            <div className="service-img">
                                <img src={f5}  className="img-fluid focusimg rounded-top " alt=""/>
                             
                            </div>
                            <div className="service-content p-4" id='diet'>
                                <div className="service-content-inner">
                                      <h3 className="d-inline-block h4 mb-4">Aromatherapy and Beauty Products</h3>
                                    <Link className="btn btn-focus  rounded-pill py-2 px-4" to="">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 " >
                        <div className="service-item">
                            <div className="service-img">
                                <img src={f6}  className="img-fluid focusimg rounded-top " alt="" /> 
                            </div>
                            <div className="service-content p-4" id='diet'>
                                <div className="service-content-inner">
                                      <h3 className="d-inline-block h4 mb-4">Yoga and Holistic Healing</h3>
                                    <button onClick={handleNavigation} className="btn btn-focus  rounded-pill py-2 px-4" >Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 " >
                        <div className="service-item">
                            <div className="service-img">
                                <img src={f7} className="img-fluid focusimg rounded-top " alt="" />
                            </div>
                            <div className="service-content p-4" id='diet'>
                                <div className="service-content-inner">
                                    <h3 className="d-inline-block h4 mb-4">Technology, Startup and Health App</h3>
                                    <button onClick={handleNavigation} className="btn btn-focus  rounded-pill py-2 px-4" >Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3 " >
                        <div className="service-item">
                            <div className="service-img">
                                <img src={f8}  className="img-fluid focusimg rounded-top " alt="" />
                            </div>
                            <div className="service-content p-4" id='diet'>
                                <div className="service-content-inner">
                                      <h3 className="d-inline-block h4 mb-4">Siddha/ Unani</h3>
                                    <button onClick={handleNavigation} className="btn btn-focus  rounded-pill py-2 px-4" >Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="col-12 text-center mt-5 pt-5 " data-wow-delay="0.2s">
                        <Link className="btn btn-primary rounded-pill py-3 px-5" to="/focussector">More info</Link>
                    </div> */}
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default Focussector
