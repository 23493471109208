import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import syhologo from '../Assets/images/syhologo.png';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        document.body.classList.toggle('no-scroll', !isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
        document.body.classList.remove('no-scroll');
    };

    return (
        <div>
            <div className="container-fluid topbar px-0 px-lg-4 bgtopbar py-2 d-none d-lg-block">
                <div className="container">
                    <div className="row gx-0 align-items-center">
                        <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                            <div className="d-flex flex-wrap">
                                <div className="border-end border-white pe-3">
                                    <Link to="https://maps.app.goo.gl/CZ9bxLUmbW7A2iYb7" target='_blank' className="text-white contactnumber text-decoration-none">
                                        <i className="fas fa-map-marker-alt text-white me-2"></i>Find A Location
                                    </Link>
                                </div>
                                <div className="ps-3">
                                    <a href="mailto:syhoindia@gmail.com" className="text-white contactnumber text-decoration-none">
                                        <i className="fas fa-envelope text-white me-2"></i>syhoindia@gmail.com
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center text-lg-end">
                            <div className="d-flex justify-content-end">
                                <div className="d-flex pe-3">
                                    <Link className="btn p-0 text-white me-3" to="https://www.facebook.com/syhokhajuraho?mibextid=ZbWKwL" target='_blank'>
                                        <i className="fab fa-facebook-f"></i>
                                    </Link>
                                    <Link className="btn p-0 text-white me-3" to="https://wa.me/9584284411" target="_blank">
                                        <i className="fab fa-whatsapp"></i>
                                    </Link>
                                    <Link className="btn p-0 text-white me-3" to="https://www.instagram.com/syhokhajuraho/" target='_blank'>
                                        <i className="fab fa-instagram"></i>
                                    </Link>
                                    <Link className="btn p-0 text-white me-0" to="/">
                                        <i className="fab fa-linkedin-in"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid nav-bar px-0 px-lg-4 py-lg-0">
                <div className="container">
                    <nav id="navmenu" className={`navbar navbar-expand-lg navbar-light navmenu ${isMobileMenuOpen ? 'open' : ''}`}>
                        <Link to="/" className="navbar-brand">
                            <img src={syhologo} alt='logo' className=' logo' />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={toggleMobileMenu}
                            aria-controls="navbarCollapse"
                            aria-expanded={isMobileMenuOpen}
                            aria-label="Toggle navigation"
                        >
                            <span className="fa fa-bars"></span>
                        </button>
                        <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`} id="navbarCollapse">
                            <div className="navbar-nav ml-4 pl-3">
                                <Link to="/" onClick={closeMobileMenu} className="nav-item nav-link active">Home</Link>
                                <div className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Administrative</span>
                                    <div className="dropdown-menu">
                                        <Link to="/leadership" onClick={closeMobileMenu} className="dropdown-item">Leadership</Link>
                                        <Link to="/allmember" onClick={closeMobileMenu} className="dropdown-item">All Members</Link>
                                    </div>
                                </div>
                                <Link to="/aboutus" onClick={closeMobileMenu} className="nav-item nav-link">About</Link>
                                <Link to="/focussector" onClick={closeMobileMenu} className="nav-item nav-link">Sectors</Link>
                                <div className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</span>
                                    <div className="dropdown-menu">
                                        <Link to="/career" onClick={closeMobileMenu} className="dropdown-item">Career</Link>
                                        <Link to="/consultancy" onClick={closeMobileMenu} className="dropdown-item">Consultancy</Link>
                                    </div>
                                </div>
                                <div className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Membership</span>
                                    <div className="dropdown-menu">
                                        <Link to="/membership_registration" onClick={closeMobileMenu} className="dropdown-item">About Membership</Link>
                                        <Link to="/membershipform" onClick={closeMobileMenu} className="dropdown-item">Membership Form</Link>
                                        <Link to="/individual" onClick={closeMobileMenu} className="dropdown-item">Individual / Practitioners</Link>
                                        <Link to="/institutions" onClick={closeMobileMenu} className="dropdown-item">Institutions/ Industries</Link>
                                    </div>
                                </div>
                                <div className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Media</span>
                                    <div className="dropdown-menu">
                                        <Link to="/press_release" onClick={closeMobileMenu} className="dropdown-item">Press Release</Link>
                                        <Link to="/event_video" onClick={closeMobileMenu} className="dropdown-item">Event Video</Link>
                                        <Link to="/gallery" onClick={closeMobileMenu} className="dropdown-item">Gallery</Link>
                                        <Link to="/newsandupdate" onClick={closeMobileMenu} className="dropdown-item">News And Updates</Link>
                                    </div>
                                </div>
                                <Link to="/contact" onClick={closeMobileMenu} className="nav-item nav-link">Contact</Link>
                                <div className="nav-btn px-3">
                                    <Link to="/membershipform" className="btn btn-primary rounded-pill text-decoration-none py-2 px-4 ms-3 flex-shrink-0">Join Now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-xl-flex flex-shrink-0 ps-4">
                            <Link to="#" className="btn btncall btn-lg-square rounded-circle position-relative wow tada" data-wow-delay=".9s">
                                <i className="fa fa-phone-alt fa-2x p-1"></i>
                            </Link>
                            <div className="d-flex flex-column ms-3">
                                <span>Contact us</span>
                                <Link to="tel:+91-9584284411" className='text-decoration-none'>
                                    <span className="text-dark contactnumber text-decoration-none">+91-9584284411</span>
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Header;
