import React from 'react';
import { Link } from 'react-router-dom';

const JoinNow = () => {
    return (
        <div>
            <section className="ftco-section ftco-no-pb ftco-no-pt py-5 wow fadeInUp tutotbg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tutotbg w-100 rounded p-4">
                                <div className="row">
                                    <div className="col-md-7 align-items-center">
                                        <h3 className="mb-4 becomemember">
                                            Become A Member of IAYC & SYHO
                                        </h3>
                                        <p className='mainheading'>
                                            Join the **Integrated Ayush Council (IAC)** and **Shri Yog Health Organization (SYHO)** to unlock a world of opportunities for health and wellness professionals. This dual membership offers access to exclusive resources, networking, and professional growth while supporting holistic health practices. 
                                        </p>
                                        <h3 className="becomemember">
                                            Are you a Faculty, Practitioner, Hospital Administrator, Manufacturer, Trader, or Other Stakeholder?
                                        </h3>
                                        <p className="mainheading">
                                            By joining both **IAC** and **SYHO**, you gain the support and resources from two leading organizations in the field of health and wellness. Strengthen your network, build your brand, and grow your business while contributing to a healthier world.
                                        </p>
                                    </div>
                                    <div className="col-md-5 d-flex align-items-center justify-content-lg-end" id='jointbn'>
                                        <div className="subscribe-form">
                                            <div className="form-group d-flext">
                                                <Link to="/membershipform" className="becometutorbtn text-decoration-none px-3 mainheading pop-button border-0">
                                                    Membership Form
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default JoinNow;
