import React from 'react'
import presedent from '../../Assets/images/presedenceofiac.png'
import president from '../../Assets/images/drpathakji.jpg'; 
import security from '../../Assets/images/security.png'
import Head from '../../components/Head';
const Leadership = () => {
   return (
      <div>
         <Head pageheading="Leadership" />
         <div className='container my-5 py-5'>
            <div className='row pb-5'>
               <div className='col-lg-7 order-2 order-xl-1 '>
                  <h2 className='administrativemain landrspace'>President</h2>
                  <h3 className='administrativesub pt-2 landrspace'>Dr. Shishta Nadda Basu</h3>
                  <p className='text-justify ourptext pt-4 landrspace'>Dr. Shishta Nadda Basu is the best doctor for Gynaecology, Obstetrics, and IVF in India, presently working as a Senior Director & Head of Department at Max Super Speciality Hospital, Shalimar Bagh, New Delhi. Dr. Shishta Nadda Basu has extensive experience in the field of Infertility, Intra-Uterine Insemination (IUI), IVF, D&C, Advanced Hysteroscopy, Laparoscopy for uterus removal, Uterine Fibroid Treatment, Ovarian cysts, Ectopic Pregnancy, Endometriosis Treatment, Polycystic ovaries, and Hysterectomy Surgery.</p>
                  <p className='text-justify ourptext pt-4 landrspace'>With her vast expertise and a commitment to patient care, Dr. Basu has helped numerous couples overcome infertility challenges. Her advanced treatment techniques and personalized approach have made her a trusted name in the field. She is known for her compassionate care and attention to detail, providing the best possible outcomes for her patients.</p>
               </div>
               <div className='col-lg-5 order-1 order-xl-2 text-right landrspace'>
                  <img src={presedent} alt='presedent' className='img-fluid presedentimg' />
               </div>
            </div>
            <div className='row py-5 my-5'>
               <div className='col-lg-5  text-left landrspace'>
                  <img src={security} alt='presedent' className='img-fluid presedentimg ' />
               </div>
               <div className='col-lg-7'>
                  <h2 className='administrativemain landrspace'>General Secretary </h2>
                  <h3 className=' administrativesub pt-2 landrspace'>Dr.Bipin kumar</h3>
                  <p className='text-justify ourptext pt-4 landrspace'>Dr. Bipin Kumar serves as the General Secretary of the renowned Vishwa Hindi Parishad. His proactive involvement revolves around the promotion of Hindi and the Ayush system in India, where he collaborates closely with various governmental ministries, state governments, corporations, banks, industry associations, MSMEs, youth, and emerging entrepreneurs.</p>
                  <p className='text-justify ourptext pt-4 landrspace'>Driven by a strong belief in devotion, dedication, and selfless service, Dr. Bipin Kumar has made it his mission to unite Indians and contribute to the social, cultural, economic growth, development, and empowerment of India. Additionally, he is committed to uplifting the weaker and underprivileged sections of society, striving to bring them into the mainstream and ensuring their overall well-being.</p>
               </div>
            </div>
            <div className='row pb-5 '>
               <div className='col-lg-7'>
                  <h3 className='administrativesub pt-2'>Dr. Raghav Pathak</h3>
                  <h2 className='administrativemain'>President of Integrated AYUSH Council, Madhya Pradesh</h2>

                  <p className='text-justify ourptext pt-4'>
                     Dr. Raghav Pathak, the VISIONARY LEADER of the Shri Yog Health Organization (SYHO) and the ADHYAKSH of the INTEGRATED AYUSH COUNCIL of MADHYA PRADESH, is dedicated to promoting Holistic Well-being and Spiritual Growth. With a deep passion for Yoga, Meditation, and the teachings of Indian Culture, he has spearheaded several initiatives aimed at uplifting the Health and Educational Standards of children in Madhya Pradesh. Under his guidance, SYHO has launched the "NIDAN" Project, focusing on improving the Physical and Mental Health of school children. Dr. Pathak’s commitment to Service, combined with his Expertise, has made him a Respected Figure in the fields of Yoga and Community Wellness.
                  </p>
                  <p className='text-justify ourptext '>
                     At Shri Yog Health Organization (SYHO), we are committed to enhancing the lives and well-being of children across Madhya Pradesh through our "Nidan" project. With a focus on ensuring safe and healthy living, we aim to uplift the health standards of school children, especially in rural areas.
                  </p>
                  <p className='text-justify ourptext'>
                     Together, we are committed to shaping a healthier, brighter future for our children through dedicated efforts in health and education.
                  </p>
               </div>
               <div className='col-lg-5 text-right'>
                  <img src={president} alt='President' className='img-fluid presedentimg' />
               </div>
            </div>


         </div>
      </div>
   )
}

export default Leadership
