import React from 'react';


const Marquee = () => {
  return (
    <div className="marquee">
      <div className="marquee-content">
        <p>Welcome to Shri Yog Health Orginization Madhaya Pradesh</p>
      </div>
    </div>
  );
};

export default Marquee;
