import React , {useEffect, useState} from 'react';
import Slider from '../../components/Slider'
import Focussector from '../../components/Focussector'
import Marquee from '../../components/Marquee'
import JoinNow from '../../components/JoinNow'
import Why_choose_us from '../about/Why_choose_us'
// import FullScreenVideo from '../../components/FullScreenVideo'
import NewsUpdate from '../../components/NewsUpdate'
import Donation from '../about/Donatenow'
import OurPArtners from '../about/OurPArtners'
import ContactHome from '../contact/ContactHome'
import OurStackholder from '../about/OurStackholder'
import Vission from '../about/Vission';
import axios from 'axios';

const Home = () => {

  const [homeData, setHomeData] = useState({})

  const getHome = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/home').then((data) => {
      if (data) {
        setHomeData(data.data)
      }
    })
  }
  useEffect(() => {
    getHome()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Slider images={homeData.banner_data} />
      <Marquee />
      <Focussector />
      <Why_choose_us />
      <JoinNow />
      <OurStackholder />
      <Donation />
      <NewsUpdate />   
      <Vission />
      <OurPArtners />
      {/* <ContactHome /> */}
    </div>
  )
}

export default Home
