import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    response: null,
    loading: false,
    error: null,
};

export const membership = createSlice({
    name: 'membership',
    initialState,
    reducers: {
        addMembership: (state, payload) => {
            state.loading = true;
        },
        success: (state, { payload }) => {
            state.loading = false;
            state.response = payload
        },
        failed: (state) => {
            state.loading = false;
            state.response = null;
        },
        getMembershipDetail: (state) => {
            state.loading = true;
        }
    },
})

// Action creators are generated for each case reducer function
export const { success, failed, addMembership } = membership.actions

export default membership.reducer