import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Head from '../../components/Head'

const Gallery = () => {
  const [visibleImages, setVisibleImages] = useState(12);
  const [images, setImages] = useState([]);
  const getImages = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/gallery').then((data) => {
      if (data) {
        setImages(data.data)
      }
    })
  }
  useEffect(() => {
    getImages()
  }, [])

  const handleViewMore = () => {
    setVisibleImages(prev => Math.min(prev + 12, images.length));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Head pageheading="Gallery" />
      <div className='container my-lg-5 py-5'>
        <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: "800px" }}>
          <h4 className="mainheading">Mission Moments</h4>
          <h3 className="subheading mb-lg-4">Capturing Our Journey of Service and Compassion</h3>
        </div>
        <div className="row allmemberspace">
        {images.slice(0, visibleImages).map((image, index) => (
            <div className="col-lg-4 col-md-4 col-xs-6 pb-4 thumb" key={index} >
              <a className="thumbnail" href="" data-toggle="modal" data-target="#image-gallery">
                <img className="img-thumbnail" src={`${process.env.REACT_APP_BACKEND}gallery/${image.image}`} alt={`Gallery item ${index + 1}`} />
              </a>
            </div>
          ))}
        </div>
        {visibleImages < images.length && (
          <div className="text-center mt-5">
            <button className="border-0 px-5 py-3 btn-primary rounded-pill btn-lg" onClick={handleViewMore}>View More</button>
          </div>
        )}
      </div>

    </div>

  )
}

export default Gallery
