import React from 'react';
import Head from '../../components/Head';

const Policies = () => {
  return (
    <div>
      <Head pageheading="Policies" />
      <div className='container my-5 py-5'>
        <div className='row'>
          <div className='col'>
            <h1 className="pb-5 text-center mainheading">Integrated Ayush Council: Weaving Wellness Through Tradition and Science</h1>
            <ol>
              <li className='py-3'>
                <strong className='liheading'>Regulatory Compliance:</strong>
                <ul className='ourptext pt-2'>
                  <li>Ensure adherence to national and regional regulations governing Ayush practices.</li>
                  <li>Establish policies for practitioners and businesses to meet licensing and certification requirements.</li>
                </ul>
              </li>
              <li className='py-3'>
                <strong className='liheading'>Education and Training:</strong>
                <ul className='ourptext pt-2'>
                  <li>Develop policies for the accreditation of Ayush educational institutions.</li>
                  <li>Implement standards for the curriculum and training programs in Ayush disciplines.</li>
                  <li>Promote continuous education and professional development for Ayush practitioners.</li>
                </ul>
              </li>
              <li className=' py-3'>
                <strong className='liheading'>Quality Assurance:</strong>
                <ul className='ourptext pt-2'>
                  <li>Define and enforce quality standards for Ayush products and services.</li>
                  <li>Establish protocols for quality control, including the sourcing of raw materials and manufacturing processes.</li>
                </ul>
              </li>
              <li className=' py-3'>
                <strong className='liheading'>Research and Development:</strong>
                <ul className='ourptext pt-2'>
                  <li>Encourage and support research initiatives in Ayush through funding and collaboration.</li>
                  <li>Establish policies for ethical research practices and the dissemination of research findings.</li>
                </ul>
              </li>
              <li className=' py-3'>
                <strong className='liheading'>Business Development:</strong>
                <ul className='ourptext pt-2'>
                  <li>Formulate policies to support the growth and development of Ayush businesses.</li>
                  <li>Provide guidelines for market entry, product promotion, and business sustainability.</li>
                </ul>
              </li>
              <li className=' py-3'>
                <strong className='liheading'>Healthcare Integration:</strong>
                <ul className='ourptext pt-2'>
                  <li>Develop policies for the integration of Ayush practices into the broader healthcare system.</li>
                  <li>Establish protocols for collaboration between Ayush practitioners and allopathic healthcare professionals.</li>
                </ul>
              </li>
              <li className=' py-3'>
                <strong className='liheading'>Community Engagement:</strong>
                <ul className='ourptext pt-2'>
                  <li>Implement policies to promote awareness and understanding of Ayush practices within communities.</li>
                  <li>Facilitate outreach programs and health education initiatives.</li>
                </ul>
              </li>
              <li className=' py-3'>
                <strong className='liheading'>International Collaboration:</strong>
                <ul className='ourptext pt-2'>
                  <li>Formulate policies for international collaboration in the field of Ayush, fostering global partnerships and knowledge exchange.</li>
                  <li>Establish guidelines for the export and promotion of Ayush products and services internationally.</li>
                </ul>
              </li>
              <li className=' py-3'>
                <strong className='liheading'>Ethical Practices:</strong>
                <ul className='ourptext pt-2'>
                  <li>Develop and enforce ethical guidelines for Ayush practitioners and businesses.</li>
                  <li>Address issues related to patient confidentiality, informed consent, and professional conduct.</li>
                </ul>
              </li>
              <li className=' py-3'>
                <strong className='liheading'>Accessibility and Affordability:</strong>
                <ul className='ourptext pt-2'>
                  <li>Formulate policies to ensure that Ayush services and products are accessible to a diverse population.</li>
                  <li>Explore measures to make Ayush treatments and remedies affordable for all socio-economic groups.</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policies;
