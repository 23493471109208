import React from 'react'
const CenterHeading = ({centerheading}) => {
  return (
    <div>
       <h1 className="pb-5 text-center mainheading">{centerheading}</h1>
    </div>
  )
}

export default CenterHeading
