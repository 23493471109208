import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaEnvelopeOpen, FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Head from '../../components/Head';
import CommonAlert from '../../common/CommonAlert';
import { addContectUs, failed } from '../../slice/contactusSlice';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../common/loader/loader.gif';
import { postRequest } from '../../apis/ApiInstence';

const Contact = () => {
    const dispatch = useDispatch();
    const data = useSelector(store => store.contact);

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Validation function
    const validate = () => {
        const errors = {};
        if (!formData.name) {
            errors.name = "Name is required";
        }
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.mobile)) {
            errors.mobile = "Phone number must be exactly 10 digits";
        }
        if (!formData.subject) {
            errors.subject = "Subject is required";
        }
        if (!formData.message) {
            errors.message = "Message is required";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await postRequest(process.env.REACT_APP_BACKEND_URL + 'contactus', formData);
                if (response.status === 201) {
                    setIsSubmitted(true);
                    setFormData({
                        name: '',
                        mobile: '',
                        subject: '',
                        message: '',
                    });
                }
            } catch (error) {
                dispatch(failed());
            }
        }
    };

    const onConfirm = () => {
        setIsSubmitted(false);
        dispatch(failed());
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            {isSubmitted && (
                <CommonAlert
                    title="Thanks For Contacting Us"
                    text="We will contact you soon"
                    icon="success"
                    confirmButtonText="Ok"
                    onConfirm={onConfirm}
                />
            )}
            <Head pageheading="Contact Us" />
            <div className="container mt-lg-5 pt-5">
                <h2 className="text-center aboutheading">Contact Us</h2>
                <h6 className='text-center pt-2 pb-5 allmemberspace'>Get in Touch with Us for Any Queries, or Support. We're Here to Help You!</h6>
                <div className="pt-5 row allmemberspace ">
                    <div className="col-lg-5 col-md-12 leftspace">
                        <div className="d-flex flex-column justify-content-center px-4 py-5 contactinfo">
                            <div className="d-flex my-3">
                                <FaMapMarkerAlt className="fa-2x contacticon flex-shrink-0 mr-3" />
                                <div>
                                    <h5 className="text-white">Address</h5>
                                    <p>
                                        <Link
                                            className="text-white text-decoration-none"
                                            to="https://maps.app.goo.gl/CZ9bxLUmbW7A2iYb7"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Sewagram Road Khajuraho District Chhatarpur, Khajuraho, Madhya Pradesh India
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex my-2">
                                <FaEnvelopeOpen className="fa-2x contacticon flex-shrink-0 mr-3" />
                                <div>
                                    <h5 className="text-white">Email Address</h5>
                                    <p>
                                        <Link to="mailto:syhoindia@gmail.com" className="text-light text-decoration-none">
                                            syhoindia@gmail.com
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex my-2">
                                <FaPhoneAlt className="fa-2x contacticon flex-shrink-0 mr-3" />
                                <div>
                                    <h5 className="text-white">Contact Number</h5>
                                    <p className="m-0">
                                        <Link to="tel:9584284411" className="text-light text-decoration-none">
                                            9584284411
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 mb-lg-5">
                        <div className="contact-form bg-light mb-5 p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-md-6 form-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control contactinputs"
                                            placeholder="Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.name && <small className="text-danger">{errors.name}</small>}
                                    </div>
                                    <div className="col-12 col-md-6 form-group mb-3">
                                        <input
                                            type="tel"
                                            className="form-control contactinputs"
                                            placeholder="Your Phone"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control contactinputs"
                                        placeholder="Subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.subject && <small className="text-danger">{errors.subject}</small>}
                                </div>
                                <div className="form-group mb-3">
                                    <textarea
                                        className="form-control contactinputs"
                                        rows="4"
                                        placeholder="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                    {errors.message && <small className="text-danger">{errors.message}</small>}
                                </div>
                                <div className='py-3'>
                                    <button className="submitbtn" type="submit">
                                        {data?.loading ? <img src={loader} alt="loader" width={50} /> : 'Send Message'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28964.53148025258!2d79.90503552099898!3d24.84449652628371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3982e606a486e093%3A0x29e9b56680aaa724!2sSevagram%2C%20Khajuraho%2C%20Madhya%20Pradesh%20471606!5e0!3m2!1sen!2sin!4v1734104981234!5m2!1sen!2sin" 
                loading="lazy" className='border-0 map'  referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
};

export default Contact;
