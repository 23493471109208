import React, { useState, useEffect } from 'react';
import Head from '../../components/Head';
import { useDispatch, useSelector } from 'react-redux';
import { failed } from '../../slice/membershipSlice';
import CommonAlert from '../../common/CommonAlert';
import { postRequest } from '../../apis/ApiInstence';
import qrcode from '../../Assets/images/qrcode.jpeg';
import s15 from '../../Assets/images/s15.jpg';
import s25 from '../../Assets/images/s25.jpg';
import s35 from '../../Assets/images/s35.jpg';
import s51 from '../../Assets/images/s51.jpg';

const MembershipForm = () => {
  const dispatch = useDispatch();
  const data = useSelector(store => store.appointment);
  const [formSubmit, setFormSubmit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);
  const [formData, setFormData] = useState({
    category: '',
    name: '',
    gender: '',
    blood_group: '',
    tags: '',
    health_wellness: '',
    occupation: '',
    organisation: '',
    address: '',
    district: '',
    state: '',
    zipcode: '',
    phone: '',
    email: '',
    degree: '',
  });


  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? e.target.files[0] : value,
    });
  };

  const handleScreenshotChange = (e) => {
    setPaymentScreenshot(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmit(true);
    const paymentModal = new window.bootstrap.Modal(document.getElementById('paymentModal'));
    paymentModal.show();
  };

  const handlePaymentSubmit = async () => {
    if (!paymentScreenshot) {
      alert('Please upload a payment screenshot before proceeding.');
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    // formDataToSend.append('paymentScreenshot', paymentScreenshot);

    try {
      await postRequest(process.env.REACT_APP_BACKEND_URL + 'membership', formDataToSend);
      setIsSubmitted(true);
      const paymentModal = window.bootstrap.Modal.getInstance(document.getElementById('paymentModal'));
      paymentModal.hide();
      resetForm();
    } catch (error) {
      console.error('There was a problem submitting the form:', error);
      dispatch(failed());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const resetForm = () => {
    setFormData({
      category: '',
      name: '',
      gender: '',
      blood_group: '',
      tags: '',
      health_wellness: '',
      occupation: '',
      organisation: '',
      address: '',
      district: '',
      state: '',
      zipcode: '',
      phone: '',
      email: '',
      degree: '',
    });
    setFormSubmit(false);
    setPaymentScreenshot(null);
  };

  const onConfirm = () => {
    setIsSubmitted(false);
    dispatch(failed());
  };


  return (
    <div>
      {isSubmitted && (
        <CommonAlert
          title="Membership Form Submitted Successfully"
          text="We will contact you soon."
          icon="success"
          confirmButtonText="Ok"
          onConfirm={onConfirm}
        />
      )}

      <Head pageheading="Register Now" />
      <div className="container my-5">
        <h2 className="administrativemain text-center">
          Are you an Integrated AYUSH Doctor, Institution, Manufacturer, Trader, or an SYHO Member?
        </h2>
        <h3 className="administrativesub fs-5 pt-3 text-center">
          Join the ‘Integrated AYUSH Council’ and the ‘Shri Yog Health Organization’ Expert Panel to build your brand, grow your business !
        </h3>


        <div className="my-5 py-5">
          <div className="col-12">
            <form onSubmit={handleSubmit} id="membership_form">
              <div className="row g-0">
                <div className="col-lg-6 genralbackground">
                  <div className="p-5">
                    <h3 className="text-white mb-5 formheading">General Information</h3>
                    <div className="row mb-5">
                      <div className="col-12">
                        <label className="form-label text-white genralflabel" htmlFor="category">Membership Type</label>
                        <select name="category" defaultValue={formData.category} onChange={handleChange} className="form-select genralselect" aria-label="Select Membership Category">
                          <option value="" disabled selected>Select Membership Type</option>
                          <option value="1500 for primary membership">₹1500 for Primary Membership</option>
                          <option value="2500 for volunteer membership">₹2500 for Volunteer Membership</option>
                          <option value="3500 for supporter membership">₹3500 for Supporter Membership</option>
                          <option value="5500 for community membership">₹5500 for Community Membership</option>
                          <option value="7500 for associate membership">₹7500 for Associate Membership</option>
                        </select>
                      </div>

                    </div>
                    <div className="row mb-5">
                      <div className="col-12">
                        <label className="form-label text-white genralflabel" htmlFor="name">Full Name</label>
                        <input type="text" name="name" defaultValue={formData.name} onChange={handleChange} className="form-control genralinputs" />
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-12">
                        <label className="form-label text-white genralflabel" htmlFor="gender">Gender</label>
                        <input type="text" name="gender" defaultValue={formData.gender} onChange={handleChange} className="form-control genralinputs" />
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-12">
                        <label className="form-label text-white genralflabel" htmlFor="blood_group">Blood Group</label>
                        <input type="text" name="blood_group" defaultValue={formData.blood_group} onChange={handleChange} className="form-control genralinputs" />
                      </div>

                    </div>
                    <div className="row mb-5">
                      <div className="col-12">
                        <label className="form-label text-white genralflabel" htmlFor="tags">Tags</label>
                        <select name="tags" defaultValue={formData.tags} onChange={handleChange} className="form-select genralselect" aria-label="Select Position">
                          <option value="" disabled selected>Select a Tag</option>
                          <option value="ayurveda">Ayurveda</option>
                          <option value="homeopathy">Homeopathy</option>
                          <option value="naturopathy">Naturopathy & Yoga</option>
                          <option value="nutritionist">Nutritionist</option>
                          <option value="siddha">Siddha</option>
                          <option value="unani">Unani</option>
                          <option value="allopathy">Allopathy</option>
                          <option value="acupuncture">Acupuncture</option>
                          <option value="physiotherapy">Physiotherapy</option>
                          <option value="dentistry">Dentistry</option>
                          <option value="pediatrics">Pediatrics</option>
                          <option value="general_medicine">General Medicine</option>
                          <option value="psychology">Psychology</option>
                          <option value="dermatology">Dermatology</option>
                          <option value="cardiology">Cardiology</option>
                          <option value="oncology">Oncology</option>
                          <option value="orthopedics">Orthopedics</option>
                          <option value="nephrology">Nephrology</option>
                          <option value="gynecology">Gynecology</option>
                          <option value="radiology">Radiology</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-12">
                        <label className="form-label text-white genralflabel" htmlFor="health_wellness">Health & Wellness</label>
                        <select name="health_wellness" defaultValue={formData.health_wellness} onChange={handleChange} className="form-select genralselect" aria-label="Select Position">
                          <option value="" disabled selected>Select a Category</option>
                          <option value="arthritis">Arthritis</option>
                          <option value="ayurveda">Ayurveda</option>
                          <option value="ayush clinics">Ayush Clinics</option>
                          <option value="colleges">Colleges</option>
                          <option value="diabetes">Diabetes</option>
                          <option value="dieticians">Dieticians</option>
                          <option value="heart diseases">Heart Diseases</option>
                          <option value="homeopathy">Homeopathy</option>
                          <option value="hospitals">Hospitals</option>
                          <option value="hypertension">Hypertension</option>
                          <option value="insomnia">Insomnia</option>
                          <option value="institutions">Institutions</option>
                          <option value="joint pain">Joint Pain</option>
                          <option value="manufacturer">Manufacturer</option>
                          <option value="migraine">Migraine</option>
                          <option value="other stakeholder/faculty/research scholar">Other Stakeholder/Faculty/Research Scholar</option>
                          <option value="practitioner">Practitioner</option>
                          <option value="practitioners">Practitioners</option>
                          <option value="products">Products</option>
                          <option value="siddha">Siddha</option>
                          <option value="trader">Trader</option>
                          <option value="unani">Unani</option>
                          <option value="universities">Universities</option>
                          <option value="wellness center">Wellness Center</option>
                          <option value="wellness resorts">Wellness Resorts</option>
                          <option value="yoga naturopathy">Yoga & Naturopathy</option>
                          <option value="primary health worker ">Primary Health Worker </option>
                        </select>
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className="col-12">
                        <label className="form-label text-white genralflabel" htmlFor="occupation">Occupation</label>
                        <input type="text" name="occupation" defaultValue={formData.occupation} onChange={handleChange} className="form-control genralinputs" />
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className="col-12">
                        <label className="form-label text-white genralflabel" htmlFor="organisation">Organisation/Company Name</label>
                        <input type="text" name="organisation" defaultValue={formData.organisation} onChange={handleChange} className="form-control genralinputs" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 bg-light text-dark">
                  <div className="p-5">
                    <h3 className="formheading mb-5">Contact Details</h3>
                    <div className='row mb-4'>
                      <div className="col-12">
                        <label className="form-label genralflabel" htmlFor="address">Address</label>
                        <input type="text" name="address" defaultValue={formData.address} onChange={handleChange} className="form-control contactinputs" />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12">
                        <label className="form-label genralflabel" htmlFor="district">Districts</label>
                        <input type="text" name="district" defaultValue={formData.district} onChange={handleChange} className="form-control contactinputs" />
                      </div>

                    </div>
                    <div className='row mb-4'>
                      <div className="col-12">
                        <label className="form-label genralflabel" htmlFor="state">State</label>
                        <input type="text" name="state" defaultValue={formData.state} onChange={handleChange} className="form-control contactinputs" />
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className="col-12">
                        <label className="form-label genralflabel" htmlFor="zipcode">Zip code</label>
                        <input type="text" name="zipcode" defaultValue={formData.zipcode} onChange={handleChange} className="form-control contactinputs" />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12">
                        <label className="form-label genralflabel" htmlFor="phone">Phone</label>
                        <input type="text" name="phone" defaultValue={formData.phone} onChange={handleChange} className="form-control contactinputs" />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12">
                        <label className="form-label genralflabel" htmlFor="email">Email</label>
                        <input type="text" name="email" defaultValue={formData.email} onChange={handleChange} className="form-control contactinputs" />
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className="col-12">
                        <label className="form-label genralflabel" htmlFor="degree">Highest Degree Obtained</label>
                        <input type="text" name="degree" defaultValue={formData.degree} onChange={handleChange} className="form-control contactinputs" />
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className="mb-4">
                        <label className="form-label genralflabel" htmlFor="image">Image</label>
                        <input type="file" name="imageQR"  className="form-control contactinputs" />
                      </div>
                    </div>
                    <button type="submit" className="submitbtn btn-lg">Submit</button>
                  </div>
                </div>
              </div>
            </form>
            <div className="modal fade" id="paymentModal" tabIndex="-1" role="dialog" aria-labelledby="paymentModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="paymentModalLabel">Complete Payment</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p className='becomemember fs-5'>Scan the QR code below to complete your membership payment:</p>
                    <div className="text-center">
                      {/* <img src={qrcode} alt="QR Code" className="img-fluid" width={200} /> */}
                      {formData.category === "1500 for primary Membership" && <img src={s15} alt="QR Code" className="img-fluid" width={200} />}
                      {formData.category === "2500 for volunteer membership" && <img src={s25} alt="QR Code" className="img-fluid" width={200} />}
                      {formData.category === "3500 for supporter membership" && <img src={s35} alt="QR Code" className="img-fluid" width={200} />}
                      {formData.category === "5100 for community membership" && <img src={s51} alt="QR Code" className="img-fluid" width={200} />}
                      {formData.category === "7500 for associate membership" && <img src={qrcode} alt="QR Code" className="img-fluid" width={200} />}
                    </div>
                    <label htmlFor="screenshot" className="mt-3">Please submit a screenshot of your payment:</label>
                    <input type="file" id="screenshot" name="image" onChange={(e) => {
                      handleChange(e)
                      handleScreenshotChange(e)
                    }} className="btn btn-block addbtn mt-2" />
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="submitbtndonation" onClick={handlePaymentSubmit}>Submit Payment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipForm;
