import React from 'react';
import CenterHeading from '../../components/CenterHeading'
import Head from '../../components/Head'
const EventVideo = () => {
    const videos = [
        {
            title: 'नेशनल आयुष कॉन्फ़्रेन्स सफलतापूर्वक सम्पन्न',
            videoUrl: 'https://www.youtube.com/embed/wdvq63HD5Qc' 
        },
        {
            title: 'डॉ. बिपिन कुमार, संयोजक, आयुषकॉन 2024 और महासचिव।',
            videoUrl: 'https://www.youtube.com/embed/4S8t8MO5HBc'
        },
        {
            title: 'प्रो. प्रियंरंजन त्रिवेदी, कुलपति, ग्लोबल ओपन यूनिवर्सिटी, नागालैंड।',
            videoUrl: 'https://www.youtube.com/embed/qMOB9sJiGUE'
        },
        {
            title: 'स्वामी महेश योगी जी, अयोध्या।',
            videoUrl: 'https://www.youtube.com/embed/gebv9QD-oPo'
        },
        {
            title: 'श्री इंद्रेश कुमार, वरिष्ठ RSS प्रचारक',
            videoUrl: 'https://www.youtube.com/embed/DL5CLctjgZo'
        },
        {
            title: 'डॉ. मोहित गुप्ता, निदेशक - जी बी पंत मेडिकल कॉलेज।',
            videoUrl: 'https://www.youtube.com/embed/J5V2KhjjI5o'
        }
        
    ];
    

    return (
      <>
      <Head pageheading="Event Videos" />
      <div className='container  py-5'>
      <CenterHeading centerheading="Event Videos of Integrated Ayush Council" />
            <div className="row  allmemberspace">
                <div className="col-md-12 ">
                    <div className="row  ">
                        {videos.map((video, index) => (
                            <div key={index} className="col-lg-4 mb-4 col-md-6">
                                <div className="bg-light p-4" style={{ minHeight: '340px' }}>
                                    <div className="video-container">
                                        <iframe
                                            width="100%"
                                            height="200"
                                            src={video.videoUrl}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title={video.title}
                                        ></iframe>
                                    </div>
                                    <div className="pt-3">
                                        <h4 className='videotitle'>{video.title}</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default EventVideo;
