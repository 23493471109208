import React, { useState } from 'react';
import CommonAlert from '../../common/CommonAlert';
import { addDonations, failed } from '../../slice/donationSlice';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../common/loader/loader.gif';
import { postRequest } from '../../apis/ApiInstence';
import qrcode from '../../Assets/images/qrcode.jpeg'

const Donation = () => {
  const dispatch = useDispatch();
  const data = useSelector((store) => store.donation);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    causes: '',
    message: '',
  });

  // Validation function
  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    if (!formData.causes) {
      errors.causes = 'Causes is required';
    }
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(formData.phone)) {
      errors.phone = 'Phone number must be exactly 10 digits';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleScreenshotChange = (e) => {
    setPaymentScreenshot(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const paymentModal = new window.bootstrap.Modal(document.getElementById('exampleModalCenter'));
      paymentModal.show();
    }
  };

  const handlePaymentSubmit = async () => {
    if (!paymentScreenshot) {
      alert('Please upload a payment screenshot before proceeding.');
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append('image', paymentScreenshot);

    try {
      const response = await postRequest(process.env.REACT_APP_BACKEND_URL + 'donations', formDataToSend);
      if (response.status === 201) {
        setIsSubmitted(true);
        const paymentModal = window.bootstrap.Modal.getInstance(document.getElementById('exampleModalCenter'));
        paymentModal.hide();
        setFormData({
          name: '',
          phone: '',
          causes: '',
          message: '',
        });
        setPaymentScreenshot(null);
      }
    } catch (error) {
      console.error('Submission error:', error);
      dispatch(failed());
    }
  };

  const onConfirm = () => {
    setIsSubmitted(false);
    dispatch(failed());
  };

  return (
    <div>
      {isSubmitted && (
        <CommonAlert
          title="Thanks For Helping Us"
          text="Your contribution makes a big difference."
          icon="success"
          confirmButtonText="Ok"
          onConfirm={onConfirm}
        />
      )}
      <section className="ftco-section ftco-no-pb ftco-no-pt py-5 wow fadeInUp vissionbg">
        <div className="container">
          <div className="px-4">
            <div className="py-lg-5">
              <div className="text-center">
                <h2 className="mb-4">Support Our Cause</h2>
                <p className="mb-5">
                  Your generosity helps us continue our mission to provide healthcare and support to those in need.
                </p>
              </div>
              <form onSubmit={handleSubmit} className="donation-form rounded p-4">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control bg-transparent donationform text-white"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    required
                  />
                  {errors.name && <div className="text-danger">{errors.name}</div>}
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    className="form-control bg-transparent donationform text-white"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Your Phone"
                    required
                  />
                  {errors.phone && <div className="text-danger">{errors.phone}</div>}
                </div>
                <div className="mb-3">
                  <select name="causes" defaultValue={formData.causes} onChange={handleChange} className="form-select genralselect" aria-label="Select Position">
                    <option value="" disabled selected>Donate for Our Causes</option>
                    <option value="donate ₹251 for One Child's Health">Donate ₹251 for One Child's Health</option>
                    <option value="support a yoga camp for kids">Support a Yoga Camp for Kids</option>
                    <option value="help fund health centers">Help Fund Health Centers & Yoga</option>
                    <option value="sponsor a school health program">Sponsor a School Health Program</option>
                    <option value="donate ₹500 for medicines">Donate ₹500 for Medicines</option>
                    <option value="become a monthly donor">Become a Monthly Donor</option>
                    <option value="donate any amount">Donate Any Amount</option>
                  </select>
                  {errors.causes && <div className="text-danger">{errors.causes}</div>}
                </div>
                <div className="">
                  <textarea
                    className="form-control bg-transparent donationform text-white"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="2"
                    placeholder="Message (Optional)"
                  ></textarea>
                </div>
                <div className="pt-5 text-center">
                  <button type="submit" className="btn-lg font-weight-bold border-0 text-decoration-none bannerbtn">
                    {data?.loading ? <img src={loader} alt="loader" width={50} /> : 'Donate Now'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Payment Modal with Bootstrap styling */}
      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">Complete Payment</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className='becomemember fs-4'>Scan the QR code below to make your donation.</p>
              <div className='text-center'>
                <img src={qrcode} alt="QR Code" className='img-fluid shadow' width={300} />
              </div>
              <div className="mt-4">
                <label htmlFor="screenshot" className="mt-3">Please submit a screenshot of your payment:</label>
                <input type="file" id="screenshot" onChange={handleScreenshotChange} className="btn btn-block addbtn mt-2" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
              <button type="button" className="submitbtndonation" onClick={handlePaymentSubmit}>Submit Donation</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donation;
