import React from 'react';
import Head from '../../components/Head';

const Complaints = () => {
  return (
    <div>
      <Head pageheading="Complaints " />
      <div className='container my-5 py-5'>
        <div className='row'>
          <div className='col'>
            <h1 className="pb-5 text-center mainheading">Complaints Regarding Integrated AYUSH Council: A Multifaceted Issue</h1>
            <p className='pb-5'>The Integrated AYUSH Council (IAC), formed in 2020, regulates Ayurveda, Yoga, Unani, Siddha, and Homeopathy (AYUSH) systems in India. While the council aims to streamline regulations and improve standards, it has also faced controversies and complaints from various stakeholders.</p>
            
            <h2 className="liheading pb-3 administrativesub">Common Complaints</h2>
            <ol className='ourptext pt-2'>
              <li className='py-2'>
                <strong>Registration and Licensing Issues:</strong> 
                <ul>
                  <li>Delays in processing applications: Practitioners report long waiting periods for registration and licensing, hindering their ability to practice legally.</li>
                  <li>Unclear guidelines and inconsistencies: Allegations of ambiguities in application procedures and varying interpretations by IAC officials, leading to confusion and rejections.</li>
                  <li>Unfair eligibility criteria: Concerns about discriminatory practices based on qualifications or experience, particularly toward practitioners from traditional lineages.</li>
                </ul>
              </li>
              <li className='py-2'>
                <strong>Professional Misconduct and Disciplinary Actions:</strong> 
                <ul>
                  <li>Inadequate complaint redressal mechanisms: Accusations of delayed investigations and lack of transparency in handling complaints against practitioners.</li>
                  <li>Lenient penalties for misconduct: Critics argue that punishments for offenses like negligence or malpractice are insufficient to deter violations.</li>
                  <li>Lack of public awareness: Many patients remain unaware of avenues for reporting inappropriate conduct by practitioners, limiting accountability.</li>
                </ul>
              </li>
              <li className='py-2'>
                <strong>Policy and Governance Concerns:</strong> 
                <ul>
                  <li>Dominance of Ayurveda: Apprehensions that the council prioritizes Ayurveda over other AYUSH systems, marginalizing other practices.</li>
                  <li>Lack of transparency in decision-making: Allegations about insufficient public consultation and closed-door decision-making processes within the IAC.</li>
                  <li>Limited stakeholder engagement: Some practitioners feel excluded from policy discussions that directly impact their profession.</li>
                </ul>
              </li>
            </ol>

            <h2 className="liheading administrativesub py-3">Addressing the Complaints</h2>
            <p className='ourptext'>
              The IAC has taken steps to address these concerns, including streamlining registration processes, setting up grievance redressal committees, and conducting awareness campaigns. However, challenges remain, and consistent efforts are needed to ensure transparency, fairness, and effective regulation.
            </p>

            <h2 className="liheading administrativesub py-3">Moving Forward</h2>
            <p className='ourptext'>
              Open dialogue and collaboration between the IAC, practitioners, patients, and other stakeholders are crucial to addressing existing complaints and preventing future ones. Implementing clear guidelines, improving communication channels, and ensuring responsive grievance redressal mechanisms can build trust and strengthen the effectiveness of the IAC in regulating the AYUSH sector.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Complaints;
