import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import counterSlice from '../slice/counterSlice';
import authSlice from '../slice/authSlice';
import rootSaga from '../apis/rootSaga';
import  productSlice from '../slice/productSlice';
import userSlice from '../slice/userSlice';
import cartSlice from '../slice/cartSlice';
import contactusSlice from '../slice/contactusSlice';
import paymentSlice from '../slice/paymentSlice';
import orderSlice from '../slice/orderSlice';
import appointmentSlice from '../slice/appointmentSlice';
import membershipSlice  from '../slice/membershipSlice';
import donationSlice from '../slice/donationSlice';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    counter : counterSlice,
    auth: authSlice,
    product: productSlice,
    user: userSlice,
    cart: cartSlice,
    contact: contactusSlice,
    donation:donationSlice,
    payment: paymentSlice,
    order: orderSlice,
    appointment: appointmentSlice,
    membership: membershipSlice
  },
  middleware: ()=>[sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;