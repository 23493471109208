import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Slider = (props) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.images && Array.isArray(props.images)) {
      setImages(props.images);
    }
  }, [props.images]);

  return (
    <div className="slider">
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {images.length > 0 ? (
            images.map((item, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img
                  className="img-fluid w-100 sliderimg "
                  loading="lazy"
                  src={`${process.env.REACT_APP_BACKEND}banner/${item.image}`}
                  alt={`slide-${index}`}
                />
              </div>
            ))
          ) : (
            <div className="carousel-item active">
              <p className="text-center">No images available</p>
            </div>
          )}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Slider;
