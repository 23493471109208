import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Head from '../../components/Head';

const Newsandupdate = () => {
  const [visibleItems, setVisibleItems] = useState(12);

  const [images, setImages] = useState([]);
  const getImages = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/media').then((data) => {
      if (data) {
        setImages(data.data)
      }
    })
  }
  useEffect(() => {
    getImages()
  }, []) 

  const handleViewMore = () => {
    setVisibleItems((prev) => prev + 12);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Head pageheading="News and Updates" />
      <div className='bg-light py-5'>
        <div className="container-fluid blog py-5">
          <div className="container">
            <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: "800px" }}>
              <h4 className="mainheading">News And Updates</h4>
              <h3 className="subheading mb-4">Highlights of Recent Achievements and Announcements</h3>
            </div>
            <div className="row g-4 justify-content-center mb-5">
            {images.slice(0, visibleItems).map((image, index) => (
                <div className="col-lg-6 col-xl-4 wow fadeInUp" data-wow-delay="0.2s" key={index}>
                  <div className="blog-item">
                    <div className="blog-img">
                      <img  src={`${process.env.REACT_APP_BACKEND}media/${image.image}`} className="img-fluid newsimg rounded-top w-100"  alt={`Media item ${index + 1}`} />
                    </div>
                    <div className="blog-content p-4">
                      <h3 className="d-inline-block newheading mb-4">{image.name}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {visibleItems < images.length  && (
              <div className="text-center">
                <button onClick={handleViewMore} className="btn btn-primary rounded-pill mt-5 py-3 px-5">View More</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsandupdate;
