import React , {useEffect} from 'react';
import Head from '../../components/Head';
const MemberDetails = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
        <Head pageheading="Blog Details" />
      <div className="blog-single my-5">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-12 m-15 px-tb">
              <article className="article">
                <div className="article-img">
                  <img
                    src="https://www.bootdey.com/image/800x350/87CEFA/000000"
                    title=""
                    alt=""
                  />
                </div>
                <div className="article-title pt-4">
                  <h1 className="mb-0 administrativemain pb-2 text-capitalize">Dr.Shishta Nadda Basu</h1>
                  <h3 className='administrativesub pt-2'>Member of National Advisory committee of Integrated Ayush Council</h3>
                  {/* <div className="media">
                    <div className="media-body">
                      <label>Rachel Roth</label>
                      <span>26 FEB 2020</span>
                    </div>
                  </div> */}
                </div>
                <div className="article-content">
                  <p className='text-justify'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p className='text-justify'>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  
                  <p className='text-justify'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <p className='text-justify'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
             
              </article>
              {/* <div className="contact-form article-comment">
                <h4>Leave a Reply</h4>
                <form id="contact-form" method="POST">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="Name"
                          id="name"
                          placeholder="Name *"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="Email"
                          id="email"
                          placeholder="Email *"
                          className="form-control"
                          type="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Your message *"
                          rows="4"
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="send">
                        <button className="px-btn theme">
                          <span>Submit</span> <i className="arrow"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            <div className="col-lg-4 m-15px-tb blog-aside">
              
           
              {/* Latest Post */}
              {/* <div className="widget widget-latest-post">
                <div className="widget-title">
                  <h3>Latest Post</h3>
                </div>
                <div className="widget-body">
                  <div className="latest-post-aside media">
                    <div className="lpa-left media-body">
                      <div className="lpa-title">
                        <h5>
                          <a href="#">
                            Prevent 75% of visitors from google analytics
                          </a>
                        </h5>
                      </div>
                      <div className="lpa-meta">
                        <a className="name" href="#">
                          Rachel Roth
                        </a>
                        <a className="date" href="#">
                          26 FEB 2020
                        </a>
                      </div>
                    </div>
                    <div className="lpa-right">
                      <a href="#">
                        <img
                          src="https://www.bootdey.com/image/400x200/FFB6C1/000000"
                          title=""
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="latest-post-aside media">
                    <div className="lpa-left media-body">
                      <div className="lpa-title">
                        <h5>
                          <a href="#">
                            Prevent 75% of visitors from google analytics
                          </a>
                        </h5>
                      </div>
                      <div className="lpa-meta">
                        <a className="name" href="#">
                          Rachel Roth
                        </a>
                        <a className="date" href="#">
                          26 FEB 2020
                        </a>
                      </div>
                    </div>
                    <div className="lpa-right">
                      <a href="#">
                        <img
                          src="https://www.bootdey.com/image/400x200/FFB6C1/000000"
                          title=""
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="latest-post-aside media">
                    <div className="lpa-left media-body">
                      <div className="lpa-title">
                        <h5>
                          <a href="#">
                            Prevent 75% of visitors from google analytics
                          </a>
                        </h5>
                      </div>
                      <div className="lpa-meta">
                        <a className="name" href="#">
                          Rachel Roth
                        </a>
                        <a className="date" href="#">
                          26 FEB 2020
                        </a>
                      </div>
                    </div>
                    <div className="lpa-right">
                      <a href="#">
                        <img
                          src="https://www.bootdey.com/image/400x200/FFB6C1/000000"
                          title=""
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* End Latest Post */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDetails;
