import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  response: null,
  loading: false,
  error: null,
};

export const donation = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    addDonations: (state, payload) => {
      state.loading = true;
    },
    success: (state, {payload})=>{
        state.loading = false;
        state.response = payload
        return state
      },
      failed:(state)=>{
        state.loading = false;
        state.response = null;
      }
  },
})

// Action creators are generated for each case reducer function
export const { addDonations, success, failed } = donation.actions

export default donation.reducer