import React from 'react'

const Ourteam = () => {
  return (
    <div>
      Our team
    </div>
  )
}

export default Ourteam
